import React from 'react';
import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';
import B2BCourseTemplateModel from '@uz/unitz-models/B2BCourseTemplateModel';
import useRoute from '@vl/hooks/useGbRouteDe';
import _ from 'lodash';
import { v4 as getUUid } from 'uuid';
import { notification } from 'antd';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              const Yup = ctx.get('ValidateHandler.Yup');
              const account_id = ctx.get('@item.account_id');
              const user_id = ctx.apply('currentUserModel.getUserId');
              const route = useRoute();

              const lessons = [
                {
                  name: '',
                  id: getUUid(),
                  photo_url: [],
                  description: '',
                  link_urls: [
                    {
                      content: '',
                    },
                  ],
                  materials: [],
                },
              ];
              const form = useFormik({
                initialValues: {
                  user_id,
                  name: ctx.get('@item.name') && ctx.get('@item.name'),
                  photo_url: !ctx.get('@item.photo_url')
                    ? ''
                    : [
                        {
                          url: ctx.get('@item.photo_url'),
                          status: 'success',
                        },
                      ],
                  description: ctx.get('@item.description'),
                  link_urls: _.map(ctx.get('@item.link_urls'), (item, index) => {
                    return {
                      id: index,
                      content: item,
                    };
                  }),
                  materials: _.map(ctx.get('@item.materials'), AssetModelFormatter.urlToFile()),
                  lessons: !ctx.get('@item.course_template_lessons.length')
                    ? lessons
                    : _.map(ctx.get('@item.course_template_lessons'), (lesson) => {
                        return {
                          name: _.get(lesson, 'name', ''),
                          id: getUUid(),
                          photo_url: !_.get(lesson, 'photo_url')
                            ? ''
                            : [
                                {
                                  url: _.get(lesson, 'photo_url'),
                                  status: 'success',
                                },
                              ],
                          description: _.get(lesson, 'description', ''),
                          link_urls: _.map(_.get(lesson, 'link_urls'), (link, index) => {
                            return {
                              id: index,
                              content: link,
                            };
                          }),
                          materials: _.map(_.get(lesson, 'materials'), AssetModelFormatter.urlToFile()),
                        };
                      }),
                },
                onSubmit: async (values, actions) => {
                  try {
                    const course_template = await B2BCourseTemplateModel.updateCourseTemplate({
                      id: ctx.get('@item.id'),
                      account_id,
                      set_course_template: {
                        name: values.name,
                        photo_url: _.get(values, 'photo_url.0.url'),
                        description: values.description,
                        materials: _.castArray(values.materials),
                        link_urls: `{${_.map(_.castArray(values.link_urls, []), 'content').join(',')}}`,
                      },
                      lessons: _.map(values.lessons, (item) => {
                        return {
                          ..._.omit(item, ['link_urls', 'materials', 'id', 'photo_url']),
                          user_id,
                          account_id,
                          template_id: ctx.get('@item.id'),
                          photo_url: _.get(item, 'photo_url.0.url'),
                          link_urls: `{${_.map(_.castArray(item.link_urls, []), 'content').join(',')}}`,
                          materials: _.castArray(item.materials),
                        };
                      }),
                    });
                    if (course_template) {
                      route.navigate(
                        ctx.apply('routeStore.toUrl', 'toolAccountCourseTemplateDetail', {
                          id: _.get(course_template, 'course_template.id', ''),
                        })
                      );
                    } else {
                      notification.error({
                        placement: 'bottomRight',
                        message: ctx.apply('i18n.t', 'ZoomToolError.CourseTemplate.edit.fail'),
                      });
                    }
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({
                  name: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  description: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  lessons: Yup.array().of(Yup.object().shape({})),
                }),
              });

              _.assign(form, {
                isModalVisible,
                canSubmit: form.isValid && form.dirty,
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
                array: {
                  add: (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const valueToInsert = _.clone(_.last(fieldArray));
                    const cleanItem = (val) => {
                      let rtn = '';
                      if (_.isPlainObject(val)) {
                        rtn = _.clone(val);
                        for (const key in valueToInsert) {
                          rtn[key] = cleanItem(valueToInsert[key]);
                        }
                      } else if (_.isArray(val)) {
                        rtn = [];
                      }
                      return rtn;
                    };

                    let newItemVal = cleanItem(valueToInsert);
                    if (_.isEmpty(valueToInsert)) {
                      newItemVal = {
                        name: '',
                        id: getUUid(),
                        photo_url: [],
                        description: '',
                        link_urls: [
                          {
                            content: '',
                          },
                        ],
                        materials: [],
                      };
                    } else {
                      newItemVal.id = valueToInsert.id + 1;
                    }
                    form.setFieldValue(field, [...fieldArray, newItemVal]);
                  },
                  addMultiple: async (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const quantity = await _.parseInt(_.get(form, 'values.multiple_quantity', 0));

                    const array = [...fieldArray];
                    const newArr = [];
                    _.range(quantity).map((item) => {
                      return newArr.push({
                        name: '',
                        id: getUUid() + item,
                        photo_url: [],
                        description: '',
                        link_urls: [
                          {
                            content: '',
                          },
                        ],
                        materials: [],
                      });
                    });
                    const data = _.concat(array, newArr);

                    form.setFieldValue(field, data);
                  },
                  remove: (field, index) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.cloneDeep(_.castArray(fieldValue));
                    fieldArray.splice(index, 1);
                    const newValue = [...fieldArray];
                    form.setFieldValue(field, newValue);
                  },
                },
                getUploaderProps: AssetModelFormatter.getUploaderProps(ctx, form),
              });

              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
