import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Col, Row, Alert, Upload, Progress } from 'antd';
import Button from '@uz/unitz-components-web/Button';
import InputQuillEditor from '@uz/unitz-components-web/InputQuillEditor';
import Link from '@uz/unitz-components-web/Link';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form, FormItem, Input } from 'formik-antd';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import styled from 'styled-components';
import { Image } from '@uz/unitz-components-web/Image';
import FormCreateCourseTemplateLesson from '@uz/unitz-tool-components/FormCreateCourseTemplateLesson';
import ModalProvider from '@uz/unitz-providers/ModalProvider';
import ModalConfirm from '@uz/unitz-components-web/ModalConfirm';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const CustomInput = styled.div`
  .ant-form-item {
    margin-bottom: 8px;
  }
  textarea,
  input,
  .ant-picker,
  .ant-radio-group,
  .ant-input-affix-wrapper,
  .ant-input-number {
    border-radius: 8px;
    border-color: ${gstyles.colors.border};
    &::placeholder {
      color: ${gstyles.colors.placeHolder};
      font-size: 16px;
    }
  }
  .ant-input-number {
    overflow: hidden;
  }
  .ant-select {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-radius: 8px;
        border-color: ${gstyles.colors.border};
      }
    }
  }
  .ant-radio-group {
    overflow: hidden;
    .ant-radio-button-wrapper {
      width: 50%;
      border-color: ${gstyles.colors.border};
      text-align: center;
      color: ${gstyles.colors.main};
      font-weight: 400;
      &.ant-radio-button-wrapper-checked {
        color: ${gstyles.colors.white500};
        font-weight: bold;
      }
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
  span {
    .ant-upload {
      border-radius: 8px;
      border-color: ${gstyles.colors.border};
      background-color: ${gstyles.colors.background2};
      .ant-upload.ant-upload-btn {
        padding: 0;
      }
    }
  }
`;

const CoverPhotoInput = styled(Upload.Dragger)`
  .ant-upload.ant-upload-btn {
    padding: 0px;
  }
`;

const AttachmentInputContainer = styled(CustomInput)`
  .ant-upload-list-item {
    .ant-upload-list-item-progress {
      padding-right: 32px;
    }
  }
`;
const AttachmentInput = styled(Upload.Dragger)`
  .ant-upload.ant-upload-btn {
    padding: 0px;
  }
`;

const CustomEditor = styled.div`
  .text-editor {
    .ql-toolbar {
      border-radius: 8px 8px 0 0;
      border-color: ${gstyles.colors.border};
    }
    .quill {
      .ql-container {
        border-radius: 0 0 8px 8px;
        border-color: ${gstyles.colors.border};
        .ql-editor {
          &::before {
            color: ${gstyles.colors.placeHolder};
          }
        }
      }
    }
  }
`;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const gutter = {
  xs: 16,
  sm: 16,
  md: 16,
  lg: 16,
};

const View8 = () => (
  <DIV className="component">
    <DIV forceCtx>
      <FormProvider form={ctx.get('form')}>
        <Form {...layout} className="course-form">
          <Row>
            <Col className="formStatus" span={24}>
              {!!ctx.get('form.status.error') && (
                <div className="py-2">
                  <Alert message={ctx.get('form.status.error.message')} type="error" />
                </div>
              )}
              {!!ctx.get('form.status.success') && (
                <div className="py-2">
                  <Alert message={ctx.get('form.status.success.message')} type="success" />
                </div>
              )}
            </Col>
          </Row>
          <div className="steps-content">
            <FormItem
              name="name"
              label={
                <span className="text-sm font-semibold text-sub">
                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.name')}
                </span>
              }
              required
            >
              <CustomInput>
                <Input name="name" size="medium" />
              </CustomInput>
            </FormItem>
            {/* <FormItem
              name="photo_url"
              label={
                <span className="text-sm font-semibold text-sub">
                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cover')}
                </span>
              }
            >
              <CustomInput>
                <div className="relative">
                  <CoverPhotoInput
                    {...ctx.apply('form.getUploaderProps', { fieldName: 'photo_url', multiple: false })}
                    itemRender={() => null}
                  >
                    {!ctx.get('form.values.photo_url.0') ? (
                      <div className="flex flex-col items-center justify-center py-10">
                        <div className="text-center ant-upload-text">
                          {gstyles.icons({ name: 'photo-library', size: 24, fill: gstyles.colors.sub })}
                        </div>
                        <div className="text-sm my-2 font-normal ant-upload-hint text-main">
                          {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cover_placeholder')}
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center justify-center">
                        <Image
                          alt="photo"
                          src={ctx.get('form.values.photo_url.0.url')}
                          className="overflow-hidden rounded-lg"
                          width={364}
                          height={205}
                        />
                      </div>
                    )}
                  </CoverPhotoInput>
                  {ctx.get('form.values.photo_url.0') && (
                    <div className="absolute top-0 right-0">
                      <Button type="link" name="ink" onClick={() => ctx.apply('form.setFieldValue', 'photo_url', [])}>
                        <span>{gstyles.icons({ name: 'delete', size: 16, fill: gstyles.colors.sub })}</span>
                      </Button>
                    </div>
                  )}
                  {ctx.get('form.values.photo_url.0.status') === 'uploading' && (
                    <div className="absolute inset-0 flex items-center justify-center p-4 bg-black opacity-50">
                      <Progress width={80} type="circle" percent={ctx.get('form.values.photo_url.0.percent')} />
                    </div>
                  )}
                </div>
                <div className="flex items-center mt-2">
                  <div>{gstyles.icons({ name: 'info', size: 16, fill: gstyles.colors.sub })}</div>
                  <div style={{ paddingLeft: 4 }} className="text-sub text-xs font-normal">
                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cover_hint')}
                  </div>
                </div>
              </CustomInput>
            </FormItem> */}
            <FormItem
              name="description"
              label={
                <span className="text-sm font-semibold text-sub">
                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.description')}
                </span>
              }
              required
            >
              <CustomEditor>
                <InputQuillEditor name="description" toolbarId="description" />
              </CustomEditor>
            </FormItem>
            <FormItem
              name="materials"
              label={
                <span className="text-sm font-semibold text-sub">
                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.material')}
                </span>
              }
            >
              <div className="flex items-center mb-2">
                <div>{gstyles.icons({ name: 'info', size: 16, fill: gstyles.colors.sub })}</div>
                <div style={{ paddingLeft: 4 }} className="text-sub text-xs font-normal">
                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.material_hint')}
                </div>
              </div>
              <AttachmentInputContainer>
                <AttachmentInput {...ctx.apply('form.getUploaderProps', { fieldName: 'materials' })}>
                  <div className="flex flex-col md:flex-row items-center justify-center py-10">
                    <Button className="mb-2 md:mb-0" name="ink">
                      {ctx.apply('i18n.t', 'Course.Info.Action.select_files')}
                    </Button>
                    <div className="flex flex-row items-center justify-center ml-4">
                      <span>{gstyles.icons({ name: 'cloud-upload', size: 24, fill: gstyles.colors.sub })}</span>
                      <div className="text-base font-normal text-main pl-2">
                        {ctx.apply('i18n.t', 'Course.Info.Action.drop_files_here')}
                      </div>
                    </div>
                  </div>
                </AttachmentInput>
              </AttachmentInputContainer>
            </FormItem>
            <div className="mb-6">
              {_.map(ctx.get('form.values.link_urls'), (benefit, index) => {
                return (
                  <Row key={_.get(benefit, 'id', '')} align="middle" gutter={gutter}>
                    <Col span={22}>
                      <CustomInput>
                        <FormItem name={`link_urls[${index}].content`}>
                          <InputText
                            name={`link_urls[${index}].content`}
                            size="medium"
                            placeholder={ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.url_placeholder')}
                          />
                        </FormItem>
                      </CustomInput>
                    </Col>

                    <Col span={2}>
                      <div
                        className="cursor-pointer inline-block"
                        onClick={() => ctx.apply('form.array.remove', 'link_urls', index)}
                      >
                        {gstyles.icons({
                          name: 'remove-circle',
                          fill: gstyles.colors.red500,
                          size: 24,
                        })}
                      </div>
                    </Col>
                  </Row>
                );
              })}
              <Row className="my-1" gutter={gutter}>
                <Col xs={24} md={6}>
                  <Button
                    type="outline"
                    size="small"
                    className="w-full"
                    onClick={() => ctx.apply('form.array.add', 'link_urls')}
                    icon={gstyles.icons({
                      name: 'add-circle',
                      size: 20,
                      fill: gstyles.colors.brandb500,
                      className: 'inline-block mx-2',
                    })}
                  >
                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.add_link')}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mt-4">
            <div className="text-main font-semibold text-base mb-2">
              {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.lessons')}
            </div>
            <FormCreateCourseTemplateLesson />
          </div>
          <Row justify="end" className="mt-4 steps-action">
            <Col xs={24} md={12}>
              <ResponsiveProps
                xs={{ style: { flexDirection: 'column-reverse' } }}
                md={{ style: { flexDirection: 'row' } }}
              >
                {(resProps) => (
                  <Row gutter={[16, 16]} {...resProps}>
                    <Col xs={24} md={12}>
                      {!!ctx.get('form.dirty') ? (
                        <ModalProvider>
                          <Button
                            name="ink"
                            className="w-full"
                            loading={ctx.get('form.isSubmitting')}
                            onClick={() => {
                              ctx.apply('modalModel.show', () => {
                                return (
                                  <ModalConfirm
                                    icon={gstyles.icons({ name: 'confirm-question', size: 52 })}
                                    title={ctx.apply('i18n.t', 'ZoomToolCourseTemplate.confirm_edit_title')}
                                    description={
                                      <div className="px-4 text-xl font-semibold text-main">
                                        {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.confirm_edit_description')}
                                      </div>
                                    }
                                    confirm={() => {
                                      return (
                                        <Link
                                          to={ctx.apply('routeStore.toUrl', 'toolAccountCourseTemplateDetail', {
                                            id: ctx.get('@item.id'),
                                          })}
                                        >
                                          <Button type="primary" className="w-full">
                                            {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.save_as_draft')}
                                          </Button>
                                        </Link>
                                      );
                                    }}
                                    ignore={() => {
                                      return (
                                        <Button
                                          type="primary"
                                          name="red"
                                          className="w-full"
                                          onClick={() => {
                                            ctx.apply('modalModel.hide');
                                          }}
                                        >
                                          {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.cancel')}
                                        </Button>
                                      );
                                    }}
                                  />
                                );
                              });
                            }}
                          >
                            <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cancel')}</span>
                          </Button>
                        </ModalProvider>
                      ) : (
                        <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseTemplates')}>
                          <Button name="ink" className="w-full" loading={ctx.get('form.isSubmitting')}>
                            <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cancel')}</span>
                          </Button>
                        </Link>
                      )}
                    </Col>
                    <Col xs={24} md={12}>
                      <Button
                        type="primary"
                        className="w-full"
                        loading={ctx.get('form.isSubmitting')}
                        onClick={ctx.get('form.submitForm')}
                      >
                        <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.done')}</span>
                      </Button>
                    </Col>
                  </Row>
                )}
              </ResponsiveProps>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </DIV>
  </DIV>
);

export default displayName(View8);
